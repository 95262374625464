
:root {
  --light-green-kamix: #ECF5E6;
  --green-kamix-3: rgba(94, 146, 61, 0.3);
  --green-kamix: #5e923d;
  --linear-green: linear-gradient(0deg, #DFF1E3, #DFF1E3), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);

  --red-kamix-3: rgba(191,38,38,0.3);
  --red-kamix: #BF2626;
  --linear-red: linear-gradient(0deg, #FED1D1, #FED1D1), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);

  --yellow-kamix-3: rgba(255,201,30,0.3);
  --yellow-kamix: #ffc91e;
  --linear-yellow: linear-gradient(0deg, #FEF6D1, #FEF6D1), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);

  --warning-kamix: #fb6340;
  --medium-black: #4D4D4D;
  --default-background: #F5F7FA;
  --default-background-2: #FAFAFA;

  --toastify-color-success: var(--green-kamix);
  --blue-core-ui: #321fdb;
}

.cursorView{
  cursor: pointer;
}

.bg-yellow-kamix{
  background-color: var(--yellow-kamix) !important;
}

.bg-green-kamix, .bg-accepted, .bg-validated, .bg-kyc-status-accepted, .bg-kyc-status-validated{
  background-color: var(--green-kamix) !important;
}

.bg-red-kamix, .bg-rejected, .bg-kyc-status-rejected, .bg-kyc-status-expired{
  background-color: var(--red-kamix) !important;
}

.bg-kyc-status-filled{
  background-color: var(--cui-warning) !important;
}

.bg-kyc-status-incomplete{

}

.bg-linear-green{
  background: var(--linear-green);
}

.bg-gray-kamix, .bg-filled{
  background: #999999 !important;
}

.bg-linear-yellow{
  background: var(--linear-yellow);
}

.bg-linear-red{
  background: var(--linear-red);
}

.text-underline{
  text-decoration: underline;
}

.objectFitCover{
  object-fit: cover;
}

.breakWord{
  word-break: break-all;
}

.one-line{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-justify{
  text-align: justify;
}

.text-ellipsis{
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.text-italic{
  font-style: italic;
}

.text-green-kamix{
  color: var(--green-kamix);
}

.text-yellow-kamix{
  color: var(--yellow-kamix);
}

.text-red-kamix{
  color: var(--red-kamix);
}

.text-warning-kamix{
  color: var(--warning-kamix);
}

.text-gray-kamix{
  color: #999999;
}

.kamix-logo {
  height: 60px;
  width: auto;
}

.delete{
  display:none;
}

.navigationLoaderLoader{
  z-index: 1;
  padding: 3px 15px;
  background-color: #f9edbe;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 12px;
  border: 1px solid orange;
}

.navigationLoaderLoader-2{
  z-index: 1;
  padding: 3px 15px;
  background-color: #f9edbe;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 145px;
  border: 1px solid orange;
}

.navigationLoaderLoader-3{
  z-index: 1;
  padding: 3px 15px;
  background-color: #f9edbe;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 0;
  border: 1px solid orange;
}

.italic{
  font-style: italic;
}

.inputfile2{
  width: 0.1em;
  height: 0.1em;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.language-item .dropdown-toggle::after{
  display: none;
}

.rotate-90{
  transform: rotate(90deg);
}

.text-right{
  text-align: right;
}

.status-incomplete, .status-inactive, .status-not_confirmed, .status-created, .status-unverified, .status-initialized, .status-deleted{
  font-weight: 500;
  color: black;
}

.status-filled, .status-pending , .status-rollback-pending{
  font-weight: 500;
  color: gray;
}

.status-accepted, .status-active, .status-validated, .status-done, .status-true, .status-ok, .text-low, .status-done, .status-submitted, .status-captured, .status-card_verified{
  font-weight: 500;
  color: var(--green-kamix);
}

.status-paid, .status-sent, .status-authorized, .status-rollback-done, .status-awaiting{
  font-weight: 500;
  color: var(--blue-core-ui);
}

.status-rejected, .status-blocked, .status-failed, .text-high, .status-refused, .status-revoked, .status-cancelled, .status-expired{
  font-weight: 500;
  color: var(--red-kamix);
}

.status-opened, .status-need-review{
  font-weight: 500;
  color: var(--cui-warning);
}

.status-outlined-incomplete, .status-outlined-inactive, .status-outlined-not_confirmed, .status-outlined-created, .status-outlined-unverified, .status-outlined-initialized, .status-outlined-deleted{
  font-weight: 500;
  color: black;
  padding: 2px 8px;
  border-color: black;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-outlined-filled, .status-outlined-pending , .status-outlined-rollback-pending{
  font-weight: 500;
  color: gray;
  padding: 2px 8px;
  border-color: gray;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-outlined-accepted, .status-outlined-active, .status-outlined-validated, .status-outlined-done, .status-outlined-true, .status-outlined-ok, .text-outlined-low, .status-outlined-done, .status-outlined-submitted, .status-outlined-captured, .status-outlined-card_verified, .status-outlined-approved{
  font-weight: 500;
  color: var(--green-kamix);
  padding: 2px 8px;
  border-color: var(--green-kamix);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-outlined-paid, .status-outlined-sent, .status-outlined-authorized, .status-outlined-rollback-done, .status-outlined-awaiting{
  font-weight: 500;
  color: var(--blue-core-ui);
  padding: 2px 8px;
  border-color: var(--blue-core-ui);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-outlined-rejected, .status-outlined-blocked, .status-outlined-failed, .text-outlined-high, .status-outlined-refused, .status-outlined-revoked, .status-outlined-cancelled, .status-outlined-expired, .status-outlined-error{
  font-weight: 500;
  color: var(--red-kamix);
  padding: 2px 8px;
  border-color: var(--red-kamix);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-outlined-opened, .status-outlined-need-review{
  font-weight: 500;
  color: var(--cui-warning);
  padding: 2px 8px;
  border-color: var(--cui-warning);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
}

.status-false, .text-medium{
  font-weight: 500;
  color: var(--warning-kamix);
}

.my-badge{
  text-overflow: ellipsis;
  border-radius: 5px;
  background-color: rgba(157, 165, 177,0.6);
  padding: 2px 5px;
  color: black;
  font-size: 0.95em;
}

.message-presentation{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 30vw;
}

.message-presentation-2{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 25vw;
}

.off-canvas-body{

}

.box-style::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.box-style::-webkit-scrollbar-track {
  background: white;
}

.box-style::-webkit-scrollbar-thumb {
  background: #888;
}

.dangerouslySetInnerHTML img{
  max-width: 100%;
}

.bar{
  border-left: 5px solid gray;
  border-radius: 4px;
  padding-left: 10px;
}

.inputfile {
  width: 0.1em;
  height: 0.1em;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.search-detection{
  background-color: #f9b115;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.screening-verdict{
  background-color: #e55353;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.operator-cm_orange_money{
  font-weight: 500;
  background-color: #e06e2c;
  color: white;
}

.operator-cm_mtn_mobile_money{
  font-weight: 500;
  background-color: #e0c621;
  color: black;
}

.fullscreen-modal {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.border-black{
  border-color: black !important;
}

@media screen and (max-width: 767px){

  .message-presentation{
    width: 20vw;
  }

  .message-presentation-2{
    width: 40vw;
  }

}
